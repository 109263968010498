<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
        <ul  v-for="(values,key) in errors_back2" :key="key">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card title="Payment Provider">
      <b-row>
        <!-- table -->
        <b-col cols="12">
          <b-table
            id="attributesTable"
            ref="values"
            striped
            hover
            responsive
            class="position-relative"
            :items="items"
            :fields="fields"
          >
          <template #cell(active)="data">
              <b-badge :variant="data.value ?'primary' : 'warning'" >
                {{ data.value }}
              </b-badge>
            </template>

            <template #cell(title)="data">
              <span v-if="data.item.title != null">

                <p v-if="JSON.parse(data.value).title_ar != null">
                  <span class="text-success">Title ar:</span> {{ JSON.parse(data.value).title_ar }}
                </p>
                <p v-if="JSON.parse(data.value).title_en != null">
                  <span class="text-success">Title en:</span> {{ JSON.parse(data.value).title_en }}
                </p>
              </span>
            </template>

            <template #cell(description)="data">
              <span v-if="data.item.description != null">

                <p v-if="JSON.parse(data.value).description_ar != null">
                  <span class="text-success">Description ar:</span> {{ JSON.parse(data.value).description_ar }}
                </p>
                <p v-if="JSON.parse(data.value).description_en != null">
                  <span class="text-success">Description en:</span> {{ JSON.parse(data.value).description_en }}
                </p>
              </span>
            </template>

            <template #cell(actions)="items">
              <!-- edit button value -->
              <b-button
                v-if="authPermissions.includes('update')"
                class="mr-1"
                size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="updatePaymentProviderModal(items.item)"
                v-b-modal.modal-prevent-closing
                variant="warning"
              >
                <feather-icon
                  icon="EditIcon"
                  style="width: 18px; height: 18px"
                />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- add Price Model -->
      <b-modal
        id="modal-prevent-closing"
        cancel-variant="outline-secondary"
        @ok="updatePaymentProvider(items.item)"
        ok-title="Update"
        cancel-title="Close"
        size="lg"
        centered
        :title="'Update ' + updatedPaymentProvider.name + ' Payment Provider'"
      >
        <validation-observer ref="updatedPaymentProviderForm">
          <b-form @submit.prevent>
            <b-row>
              
              <b-col md="6">
                      <validation-provider #default="{ errors }" name="payment_provider_type" rules="required">
                        <b-form-group label="Type" label-for="payment_provider_type">
                          <v-select v-model="updatedPaymentProvider.payment_gateway_type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="text" :options="gatewayTypes" :state="errors.length > 0 ? false : null" value="value"/>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-row class="w-100 mx-1">
                    
                          <!-- title -->
                        <b-col md="6">
                          <b-form-group label="Title ar" label-for="Title ar">
                            <validation-provider
                              #default="{ errors }"
                              name="Title ar"
                              rules="required"
                              >
                              <b-form-input
                                id="Title ar"
                                v-model="updatedPaymentProvider.title_ar"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Title ar"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>


                        <b-col md="6">
                          <b-form-group label="Title en" label-for="Title en">
                            <validation-provider
                              #default="{ errors }"
                              name="Title en"
                              rules="required"
                              >
                              <b-form-input
                                id="Title en"
                                v-model="updatedPaymentProvider.title_en"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Title en"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                      </b-col>
                  </b-row>


                  <b-row class="w-100 mx-1">
                    
                  <!-- title -->
                  <b-col md="6">
                    <b-form-group label="Description ar" label-for="Description ar">
                      <validation-provider
                        #default="{ errors }"
                        name="Description ar"
                        rules="required"
                        >
                        <b-form-input
                          id="Description ar"
                          v-model="updatedPaymentProvider.description_ar"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Description ar"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>


                  <b-col md="6">
                    <b-form-group label="Description en" label-for="Description en">
                      <validation-provider
                        #default="{ errors }"
                        name="Description en"
                        rules="required"
                        >
                        <b-form-input
                          id="Description en"
                          v-model="updatedPaymentProvider.description_en"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Description en"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>

              <!-- admin_fess -->
              <b-col md="6">
                <b-form-group label="Admin Fees" label-for="admin_fess">
                  <validation-provider
                    #default="{ errors }"
                    name="admin_fess"
                    rules="required"
                  >
                    <b-form-input
                      v-model="updatedPaymentProvider.admin_fees"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- provider_fees  -->
              <b-col md="6">
                <b-form-group label="Provider Fees" label-for="admin_fess">
                  <validation-provider
                    #default="{ errors }"
                    name="admin_fess"
                    rules="required"
                  >
                    <b-form-input
                      v-model="updatedPaymentProvider.provider_fees"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- refund_fees  -->
              <b-col md="6">
                <b-form-group label="Refund Fees" label-for="refund_fees">
                  <validation-provider
                    #default="{ errors }"
                    name="refund_fees"
                    rules="required"
                  >
                    <b-form-input
                      v-model="updatedPaymentProvider.refund_fees"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              <b-col md="6">
                <b-form-group label="Default Fees" label-for="default_fees">
                  <validation-provider
                    #default="{ errors }"
                    name="default_fees"
                    rules="required"
                  >
                    <b-form-input
                      v-model="updatedPaymentProvider.default_fees"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Active Option -->
              <b-col md="6">
                <b-form-group label="Active Payment Provider" label-for="active">
                  <validation-provider
                    #default="{ errors }"
                    name="active"
                    rules="required"
                  >
                    <b-form-checkbox
                    
                      switch
                      v-model="updatedPaymentProvider.active"
                    >
                      <p v-if="updatedPaymentProvider.active">
                        Enable Payment Provider
                      </p>
                      <p v-else>Disable Payment Provider</p>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Portal Active Option -->
              <b-col md="6">
                <b-form-group label="Portal Option" label-for="is_portal_order">
                  <validation-provider
                    #default="{ errors }"
                    name="is_portal_order"
                    rules="required"
                  >
                    <b-form-checkbox
                    :disabled="!updatedPaymentProvider.active"
                      switch
                      v-model="updatedPaymentProvider.is_portal_order"
                    >
                      <p v-if="updatedPaymentProvider.is_portal_order">
                        Enable For Portal Orders
                      </p>
                      <p v-else>Disable  For Portal Orders</p>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- InStore Active Option -->
              <b-col md="6">
                <b-form-group label="In-Store Option" label-for="is_instore_order">
                  <validation-provider
                    #default="{ errors }"
                    name="is_instore_order"
                    rules="required"
                  >
                    <b-form-checkbox
                    :disabled="!updatedPaymentProvider.active"
                      switch
                      v-model="updatedPaymentProvider.is_instore_order"
                    >
                      <p v-if="updatedPaymentProvider.is_instore_order">
                        Enable For In-Store Orders
                      </p>
                      <p v-else>Disable For In-Store Orders</p>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- PaymentSession Active Option -->
              <b-col md="6">
                <b-form-group label="Payment Session Option" label-for="is_payment_session">
                  <validation-provider
                    #default="{ errors }"
                    name="is_payment_session"
                    rules="required"
                  >
                    <b-form-checkbox
                    :disabled="!updatedPaymentProvider.active"
                      switch
                      v-model="updatedPaymentProvider.is_payment_session"
                    >
                      <p v-if="updatedPaymentProvider.is_payment_session">
                        Enable For Payment Session
                      </p>
                      <p v-else>Disable For Payment Session</p>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Subscription Active Option -->
              <b-col md="6">
                <b-form-group label="Subscription Option" label-for="is_subscription">
                  <validation-provider
                    #default="{ errors }"
                    name="is_subscription"
                    rules="required"
                  >
                    <b-form-checkbox
                    :disabled="!updatedPaymentProvider.active"
                      switch
                      v-model="updatedPaymentProvider.is_subscription"
                    >
                      <p v-if="updatedPaymentProvider.is_subscription">
                        Enable For Subsription Service
                      </p>
                      <p v-else>Disable For Subsription Service</p>
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <!-- error handelr -->
        <b-alert
          v-height-fade.appear
          fade
          :show="showDismissibleAlert"
          @dismissed="showDismissibleAlert = false"
          variant="danger"
        >
          <h4 class="alert-heading">Alert</h4>
          <div class="alert-body">
            <ul v-for="(values, index) in errors_back" :key="index">
              <li v-for="(value, indexVal) in values" :key="indexVal">
                {{ value }}
              </li>
            </ul>
          </div>
        </b-alert>
      </b-modal>
    </b-card>
  </div>
</template>
  
  <script>
import { required } from '@validations'
export default {
  data() {
    return {
      required,
      authPermissions: [],
      errors_back2: [],
      showDismissibleAlert: false,
      errors_back: [],
      gatewayTypes: [
        {
          text: "Paymob",
          value: "paymob",
        },
        {
          text: "Paytabs",
          value: "paytabs"
        },
        {
          text: "Paymob Flush",
          value: "paymobflush"
        },
      ],
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'payment_gateway_type',
          label: 'Payment Gateway',
        },

        {
          key: 'active',
          label: 'Active',
        },
        {
          key: 'admin_fees',
          label: 'Admin Fees',
        },
        {
          key: 'provider_fees',
          label: 'Provider Fees',
        },
        {
          key: 'refund_fees',
          label: 'Refund Fees',
        },
        {
          key: 'default_fees',
          label: 'Default Fees',
        },
        
        {
          key: 'actions',
        },
      ],
      items: [],
      updatedPaymentProvider: {},
    }
  },
  watch: {},
  created() {
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  computed: {},

  methods: {
    fetchData() {
      // fetch data of values
      axios
        .get('payment-providers')
        .then((result) => {
          this.items = result.data.data
          return this.items
        })
        .catch((err) => {
          //console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    updatePaymentProviderModal(item) {
      this.errors_back2 = []
      this.showDismissibleAlert = false
      this.updatedPaymentProvider = { ...item }
      if(item.title != null){
        this.updatedPaymentProvider.title_ar = JSON.parse(item.title).title_ar;
        this.updatedPaymentProvider.title_en = JSON.parse(item.title).title_en;
      }
      if(item.description != null){
      this.updatedPaymentProvider.description_ar = JSON.parse(item.description).description_ar;
      this.updatedPaymentProvider.description_en = JSON.parse(item.description).description_en;
      }
    },

    updatePaymentProvider() {
      delete this.updatedPaymentProvider.logo;
      if(this.updatedPaymentProvider.payment_gateway_type){
        this.updatedPaymentProvider.payment_gateway_type = this.updatedPaymentProvider.payment_gateway_type.value 
      }
      this.$refs.updatedPaymentProviderForm.validate().then((success) => {
        //  data{'hex_code' : this.hex_code}
        // fetch data of values
        axios
          .put(
            'payment-providers/' + this.updatedPaymentProvider.id + '/update',
            this.updatedPaymentProvider
          )
          .then((result) => {
            // get the value from values array to update the hex code
            const value = this.items.filter((item) => {
              if (item.id == this.updatedPaymentProvider.id) {
                item = this.updatedPaymentProvider
              }
            })
            this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
            })
            this.fetchData();
            this.$swal({
              position: 'center',
              icon: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((err) => {
            this.errors_back2.length = 0
            if (err.response.data.data != null) {
              if (err.response.data.data) {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back2.push({
                  error: err.response.data.data,
                })

              } else {
                this.errors_back2 = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back2 = []
              this.errors_back2.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      })
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
  